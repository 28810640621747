import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import Main from './containers/Main';
import './i18n/Languages';
import './scss/custom.scss';
import './App.scss';
import CONSTANTS from './constants/'

Amplify.configure(awsmobile);
class App extends Component {
  
  render() {
    console.log(process.env);
    return (
      <StripeProvider apiKey={CONSTANTS.STRIPE_API_KEY}>
        <Main />
      </StripeProvider>
    );
  }
}

export default App;
