import React from 'react'
import { CardNumberElement, CardCVCElement, CardExpiryElement } from 'react-stripe-elements';
import classes from './UI/Input/input.module.scss';
import { I18n } from 'aws-amplify';

const CardInfo = (props) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 form-group-custom mt-0">
                    <label htmlFor="cardnumber">{I18n.get('cardNumber')}</label>
                    <CardNumberElement className={`form-control ${(!props.ccard.cardNumber.isValid && props.ccard.cardNumber.touched && !props.ccard.cardNumber.focused) && 'cardValidationError'}`}
                        onChange={props.ccardHandleChange}
                        onFocus={props.ccardHandleFocus}
                        onBlur={props.ccardHandleBlur}
                        {...props.createOptions}
                        required
                    >
                    </CardNumberElement>
                    <img className="pf pf-credit-card" src="" alt="" id="brand-icon"></img>
                    {(!props.ccard.cardNumber.isValid && props.ccard.cardNumber.touched && !props.ccard.cardNumber.focused) && (
                        <p className={"mb-0 " + classes.InvalidMessage}>{I18n.get(props.ccard.cardNumber.message)}</p>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group-custom col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label htmlFor="carddate">{I18n.get('expiryDate')}</label>
                    <CardExpiryElement className={`form-control ${(!props.ccard.cardExpiry.isValid && props.ccard.cardExpiry.touched && !props.ccard.cardExpiry.focused) && 'cardValidationError'}`}
                        onChange={props.ccardHandleChange}
                        onFocus={props.ccardHandleFocus}
                        onBlur={props.ccardHandleBlur}
                        {...props.createOptions}
                        required
                    />
                    {(!props.ccard.cardExpiry.isValid && props.ccard.cardExpiry.touched && !props.ccard.cardExpiry.focused) && (
                        <p className={"mb-0 " + classes.InvalidMessage}>{I18n.get(props.ccard.cardExpiry.message)}</p>
                    )}

                </div>
                <div className="form-group-custom col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label htmlFor="cardcvv">{I18n.get('CVC')}</label>
                    <CardCVCElement className={`form-control ${(!props.ccard.cardCvc.isValid && props.ccard.cardCvc.touched && !props.ccard.cardCvc.focused) && 'cardValidationError'}`}
                        onChange={props.ccardHandleChange}
                        onFocus={props.ccardHandleFocus}
                        onBlur={props.ccardHandleBlur}
                        {...props.createOptions}
                        required
                    />
                    {(!props.ccard.cardCvc.isValid && props.ccard.cardCvc.touched && !props.ccard.cardCvc.focused) && (
                        <p className={"mb-0 " + classes.InvalidMessage}>{I18n.get(props.ccard.cardCvc.message)}</p>
                    )}
                </div>
            </div>
            <div className="justify-content-end d-flex" style={{ paddingTop: '23px' }}>
                <div className="w-100">
                    <button type="submit"
                        className="btn btn-primary mr-3 fixed-width-button w-100"
                        onClick={props.submit}>{I18n.get('completePurchase')}</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CardInfo;