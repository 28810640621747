import React from 'react';
import './icons.scss';
import thankyouImage from '../../assets/img/thankyou.png';

const SuccessIcon = (() => {
    return (
        <div className="mb-4 SuccessIcon containerBox">
            <img className="thankyouImage img-banner" alt="" src={thankyouImage} />
        </div>
    )
})

export default SuccessIcon;