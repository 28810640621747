const fr = {
    // home page
    'contributionText': "Contribute to Healthyminds Innovations",
    // plan page
    'Advocate': 'Advocate',
    'Ambassador': 'Ambassador',
    'Pioneer': 'Pioneer',
    'Donate': 'Donate',
    // content page
    'yourInformation': '1. Your information',
    'paymentDetails': '2. Payment details',
    'Google': 'Google',
    'Apple': 'Apple',
    'Pay': 'Pay',
    'agreeText': "By clicking 'Complete Purchase' above, you agree to our",
    'termsofUse': 'Terms of Use',
    'privacyPolicy': 'Privacy Policy',
    'and': 'and',
    'nameNotEmpty': 'Name should not be empty.',
    'nameNotContainSpecialChars': 'Name should not contain special characters.',
    'validName': 'Please enter valid name.',
    'emailNotEmpty': 'Email should not be empty.',
    'validEmail': 'Please enter valid email.',
    'selectCountry': 'Please select country.',
    'cardNumberNotEmpty': 'Card number should not be empty.',
    'validCardNumber': 'Please enter valid card number.',
    'expiryDateNotEmpty': 'Expiration date should not be empty.',
    'validExpiryDate': 'Please enter valid expiration date.',
    'CVCNotEmpty': 'CVC should not be empty.',
    'ValidCVC': 'Please enter valid CVC.',
    'postalCodeNotEmpty': 'Postal code should not be empty.',
    'validPostalCode': 'Please enter valid postal code.',
    'errorMessage': 'Unable to process your request',
    'creditDebitCard': 'Credit / Debit card',
    'Name': 'Name',
    'Email': 'Email',
    "Country": 'Country',
    'postalCode': 'Postal code',
    'cardNumber': 'Card Number',
    'expiryDate': 'Expiration Date',
    'CVC': 'CVC',
    'completePurchase': 'Complete Purchase',
    'processingRequest': "We are processing your payment. Please don't refresh or close this window.",
    'descriptionPioneer': "This is a non-refundable charitable donation. $800 USD will be a tax-deductible gift to Healthy Minds Innovations®, a 501(c)3 non-profit organization.",
    'descriptionAmbassador': "This is a non-refundable charitable donation. $300 USD will be a tax-deductible gift to Healthy Minds Innovations®, a 501(c)3 non-profit organization.",
    'descriptionAdvocate': "This is a non-refundable charitable donation.",
    'descriptionDefault': "This is a non-refundable charitable donation.",
    // Cart page
    'Total': 'Total',
    'change': 'change',
    'oneTimeDonation': 'One Time Donation',
    'PIONEER': 'PIONEER',
    'AMBASSADOR': 'AMBASSADOR',
    'ADVOCATE': 'ADVOCATE',
    // Ambassador page 
    'Ambassador-P1': 'As an Ambassador, you will shape future offerings to improve well-being for all and  ',
    'Ambassador-P2': 'have the chance to participate in exclusive webinars with top experts. ',
    'Ambassador-below-text-P1': 'This is a non-refundable charitable donation.',
    'Ambassador-below-text-P2': '$300 USD will be a tax-deductible gift to Healthy Minds Innovations®, a 501(c)3 non-profit organization.',
    'Includes': 'Includes',
    'Ambassador-li-1': 'Access to the current version of the Healthy Minds @Work app',
    'Ambassador-li-2': 'Live webinar with Dr. Richard Davidson - June 17, 2019 from 1-2pm CDT',
    'Ambassador-li-3': 'Live webinar with well-being experts Daniela Labra Cardero and Dr. Cortland Dahl - July 18, 2019 from 3-4pm CDT',
    // Pioneer page 
    'Pioneer-P1': 'In becoming a Healthy Minds Pioneer, you will further the impact of these innovative',
    'Pioneer-P2': 'tools for well-being, and participate in exclusive events.',
    'Pioneer-below-text-P1': 'This is a non-refundable charitable donation.',
    'Pioneer-below-text-P2': '$800 USD will be a tax-deductible gift to Healthy Minds Innovations®, a 501(c)3 non-profit organization.',
    'Pioneer-li-1': 'Access to the current version of the Healthy Minds @Work app',
    'Pioneer-li-2': 'Live webinar with Dr. Richard Davidson - June 17, 2019 from 1-2pm CDT',
    'Pioneer-li-3': 'Live webinar with well-being experts Daniela Labra Cardero and Dr. Cortland Dahl - July 18, 2019 from 3-4pm CDT',
    'Pioneer-li-4': 'Two tickets to the World We Make event - November 13, 2019 from 4:30-7pm CST in Madison, WI',
    // Advocate page 
    'Advocate-P1': 'As an Advocate, you\'ll advance our science by using the Healthy Minds @Work app, and discover new',
    'Advocate-P2': 'ways to improve your own well-being and the well-being of others.',
    'Advocate-below-text-P1': 'This is a non-refundable charitable donation.',
    'Advocate-li-1': 'Access to the current version of the Healthy Minds @Work app',
    // Success Page 
    'thanks-page-P1': 'Thank you for your contribution to Healthy',
    'thanks-page-P2': 'Minds Innovations and for your dedication to a',
    'thanks-page-P3': 'kinder, wiser, more compassionate world.',
    'thanks-page-sub-P1': 'Like you, we believe in the power of the mind to transform ourselves and our',
    'thanks-page-sub-P2': 'world. We are thrilled to be on this journey with you.',
    'thanks-page-sub-P2-bold': 'Check your email',
    'thanks-page-sub-P3': 'for more details and to get started with the app.',
    'viewReceipt': 'View payment receipt',
    'downloadApp': 'Download the app',
    'downloadApp-p1': 'Download the Healthy Minds @Work app to your',
    'downloadApp-p2': 'iPhone or Android device.',
    'enterInviteCode': 'Enter invite code',
    'enterInviteCode-P1': 'After downloading the app, please enter this',
    'enterInviteCode-P2': 'invitation code to complete registration.',
    'Enjoy': 'Enjoy',
    'enjoy-P1': 'Begin using the app and keep an eye out for a',
    'enjoy-P2': 'feedback survey in your inbox.',
    'helpText': 'Need help? Contact',
    // error page 
    'errorPageMessage': 'Something went wrong when processing your payment.',
    'downloadReceipt': 'Download Payment Receipt',
    'backtoHome': 'Back to home'
};

export default fr;