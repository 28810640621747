import React, { Component } from 'react';
import Header from '../components/common/Header';
import Checkout from './Checkout';
import { Elements } from 'react-stripe-elements';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from './404';
import SuccessPage from './Success';
import ErrorPage from './Error';

class Main extends Component {

  render() {
    return (
      <div>
        <BrowserRouter>
          <React.Fragment>
            <Header />
            <Switch>
              <Route path="/checkout/:plan" component={CheckoutPage} />
              <Route path="/notFound" component={NotFound} />
              <Route path="/success" component={SuccessPage} />
              <Route path="/error" component={ErrorPage} />
              <Route path='/' component={() => { window.location = 'https://go.hminnovations.org/limited-release'} }/>
              <Route component={NotFound} />
            </Switch>
          </React.Fragment>
        </BrowserRouter>
      </div>
    );
  }
}

export default Main;

const CheckoutPage = ({ history, location, match }) => (
  <div>
    <Elements>
      <Checkout history={history} location={location} match={match} />
    </Elements>
  </div>
);