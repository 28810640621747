import React, { Component } from 'react';
import { trimStart, trimEnd } from 'lodash';
import { API, I18n } from 'aws-amplify';
import Navtab from '../components/common/Navtab';
import BasicInfo from '../components/BasicInfo';
import Cart from '../components/Cart';
import { injectStripe } from 'react-stripe-elements';
import '../scss/stripe.scss';
import styles from '../scss/modules/loader.module.scss';
import googleLogo from '../assets/img/Google.svg';
import appleLogo from '../assets/img/Apple.svg';
import cardModal from '../assets/img/card-modal.png';
import CardInfo from '../components/CardInfo';
import AppLoader from '../components/common/AppLoader';
import { createOptions, cardBrandToPfClass, defaultFormState, plans } from '../utils/UtilFunctions';
import Footer from '../components/common/Footer';
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultFormState,
      canMakePayment: false,
      paymentRequest: null,
      amount: null
    }
  }

  componentDidMount = () => {
    API.get('paymentGateway', '/api/getcountries')
      .then(data => {
        const countriesList = Object.keys(data.countries);
        const formattedCountries = countriesList.map(i => {
          return { value: data.countries[i].code, label: i };
        });
        let updatedUserData = { ...this.state.userData };
        updatedUserData.country.options = formattedCountries;
        //updatedUserData.country.value = null;
        this.setState({
          userData: updatedUserData
        })
      });
  }

  componentWillMount() {
    let plan = this.props.match.params.plan;
    let amount;
    if (plan && plans.includes(plan.toLowerCase())) {
      plan = plan.toLowerCase();
      if (plan === 'ambassador')
        amount = 500;
      else if (plan === 'pioneer')
        amount = 1000;
      else if (plan === 'advocate')
        amount = 200;
      this.setState({ amount, plan }, () => {
        this.changePayRequestSubscription(amount);
      });
    }
    else
      this.backToHome();
  }

  backToHome = () => {
    this.props.history.push(`/`);
  }

  changePayRequestSubscription = (amount) => {
    this.setState({ canMakePayment: false });
    const paymentRequest = this.props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `Donate to Healthyminds Innovations`,
        amount: Math.round(Number(amount) * 100)
      },
    });

    paymentRequest.on('token', ({ complete, token, ...data }) => {
      const { userData } = this.state;
      const { card } = token;
      let paymentData = {
        body: {
          paymentInfo: {
            source: token.id,
            amount: Math.round(Number(this.state.amount) * 100),
            currency: "usd",
            receipt_email: trimEnd(userData.email.value)
          },
          userData: {
            firstName: trimEnd(userData.firstName.value),
            country: trimEnd(card.country),
            zipcode: trimEnd(card.address_zip),
            team: this.state.plan,
          },
          email: trimEnd(userData.email.value)
        }
      }
      this.processPayment(paymentData, complete);

    });
    paymentRequest.canMakePayment().then((result, resultd) => {
      const paymentType = !!result ? 'paybybtn' : 'paybycard';
      let paymentoption = null;
      let paymentLogo = null;

      if (result) {
        paymentoption = !result.applePay ? 'Google' : 'Apple';
        paymentLogo = !result.applePay ? googleLogo : appleLogo;
      }

      this.setState({
        canMakePayment: !!result,
        paymentRequest: paymentRequest,
        paymentType: paymentType,
        paymentOption: paymentoption,
        paymentLogo: paymentLogo
      });
    });
  }

  paymentRequestClickHandler = (e) => {
    const userData = { ...this.state.userData };
    const ccard = { ...this.state.ccard };
    let isValid = true;
    let focusElement = null;
    for (let element in userData) {
      focusElement = !focusElement && !userData[element].valid ? element : focusElement;
      isValid = userData[element].valid && isValid;
      userData[element].touched = true;
    }
    for (let element in ccard) {
      ccard[element].touched = false;
    }
    if (!isValid) {
      document.getElementById(focusElement).focus();
      e.preventDefault();
    }
    this.setState({ userData: userData, ccard: ccard, paynow: true, submitted: false });
  }

  paymentTypeChange = (event) => {
    const ccard = { ...this.state.ccard };
    for (let element in ccard) {
      ccard[element].touched = false;
    }
    this.setState({ paymentType: event.target.value, ccard: ccard });
  }

  inputFocused = (event) => {
    const name = event.target.name;
    let updatedUserData = { ...this.state.userData };
    updatedUserData[name].focused = true;
    updatedUserData[name].touched = true;

    this.setState({
      userData: updatedUserData
    });
  }

  inputBlurred = (event) => {
    const name = event.target.name;
    let updatedUserData = { ...this.state.userData };
    updatedUserData[name].focused = false;
    this.setState({
      userData: updatedUserData
    });
  }

  inputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = trimStart(target.value);
    this.inputChangeSetState(name, value);
  }
  countryOnchange = (event) => {
    this.inputChangeSetState('country', event);
  }

  inputChangeSetState = (name, value) => {
    const updatedUserData = { ...this.state.userData };
    let updatedUserElement = {
      ...updatedUserData[name]
    }

    updatedUserElement.value = value;
    const valid = this.checkValidity(value, updatedUserElement.validation);
    updatedUserElement.valid = valid.status;
    updatedUserElement.message = !valid.status ? updatedUserElement.errorMessages[valid.key] : null;
    updatedUserData[name] = updatedUserElement;
    let formIsValid = true;
    for (let elementIdentifier in updatedUserData) {
      formIsValid = updatedUserData[elementIdentifier].valid && formIsValid;
    }
    this.setState({
      userData: updatedUserData, formIsValid: formIsValid
    });
  }

  checkValidity = (value, rules) => {
    let isValid = {
      status: true,
      key: ''
    };

    if (!rules) {
      return isValid;
    }
    if (rules.isRequired && isValid.status) {
      isValid = value ? isValid : { status: false, key: 'isRequired' };
    }

    if (rules.isCharacters && isValid.status) {
      //let pattern = /^[A-Za-zΑ0-9\u00C0-\u00FF\s]+$/g;
      let pattern = /^[A-Za-zΑ0-9._\-\u00C0-\u00FF\s]+$/g;
      isValid = pattern.test(value) ? isValid : { status: false, key: 'isCharacters' };
    }

    if (rules.isaddressCheck && isValid.status) {
      let pattern = /^[a-zA-Z0-9]{1}/g
      isValid = pattern.test(value) ? isValid : { status: false, key: 'isaddressCheck' };
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength ? isValid : { status: false, key: 'minLength' };
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail && isValid.status) {
      const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) ? isValid : { status: false, key: 'isEmail' };
    }

    if (rules.isNumeric && isValid.status) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) ? isValid : { status: false, key: 'isRequired' };
    }
    return isValid;
  }

  ccardHandleChange = (event) => {
    if (event.brand) {
      this.setBrandIcon(event.brand);
    }
    const updatedCcard = { ...this.state.ccard };
    let updatedElement = { ...updatedCcard[event.elementType] }

    let elementValidity = this.checkcardValidity(event, updatedElement);
    elementValidity.value = event.value;
    updatedElement = {
      ...updatedElement,
      ...elementValidity
    }

    let ccardIsValid = true;
    updatedCcard[event.elementType] = updatedElement;

    for (let elementIdentifier in updatedCcard) {
      ccardIsValid = updatedCcard[elementIdentifier].isValid && ccardIsValid;
    }
    this.setState({ ccard: updatedCcard, ccardIsValid: ccardIsValid });
  }

  ccardHandleFocus = (event) => {
    const updatedCcard = { ...this.state.ccard };
    let updatedElement = { ...updatedCcard[event.elementType] }
    updatedElement.focused = true;
    updatedElement.touched = true
    updatedCcard[event.elementType] = updatedElement;
    this.setState({ ccard: updatedCcard });
  }

  ccardHandleBlur = (event) => {
    const updatedCcard = { ...this.state.ccard };
    let updatedElement = { ...updatedCcard[event.elementType] }
    updatedElement.focused = false;
    updatedCcard[event.elementType] = updatedElement;
    this.setState({ ccard: updatedCcard });
  }

  setBrandIcon = (brand) => {
    var brandIconElement = document.getElementById('brand-icon');
    var pfClass = 'pf-credit-card';
    if (brand in cardBrandToPfClass) {
      pfClass = cardBrandToPfClass[brand];
    }
    for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
      brandIconElement.classList.remove(brandIconElement.classList[i]);
    }
    brandIconElement.classList.add('pf');
    brandIconElement.classList.add(pfClass);
  }

  checkcardValidity = (cardEl, updatedElement) => {
    if (cardEl.empty) {
      return { isValid: false, message: updatedElement.errorMessages.empty };
    }

    if (cardEl.error) {
      return { isValid: false, message: cardEl.error.message };
    }

    if (!cardEl.complete) {
      return { isValid: false, message: updatedElement.errorMessages.complete };
    }

    return {
      isValid: true,
      message: null
    }
  }

  validateOnsubmit = () => {
    const updatedUserData = { ...this.state.userData };
    const updatedCcard = { ...this.state.ccard };
    let focusElement = null;

    for (let elementIdentifier in updatedUserData) {
      focusElement = !focusElement && !updatedUserData[elementIdentifier].valid ? elementIdentifier : focusElement;
      updatedUserData[elementIdentifier].touched = true;
    }

    if (focusElement)
      document.getElementById(focusElement).focus();

    for (let elementIdentifier in updatedCcard) {
      updatedCcard[elementIdentifier].touched = true;
    }

    this.setState(
      {
        userData: updatedUserData,
        ccard: updatedCcard,
        submitted: true,
        paynow: false
      });
  }

  submit = async (ev) => {
    ev.preventDefault();
    const { userData, formIsValid, ccardIsValid } = this.state;

    if (!formIsValid || !ccardIsValid || !this.state.amount) {
      this.validateOnsubmit();
      return;
    }
    this.setState({ loading: true });

    let { token, error } = await this.props.stripe.createToken();

    if (error || !token) {
        this.paymentStatusPage(`/error`, { errorMessage: error.message });
    } else {
    let description = "";

    switch (this.state.plan) {
      case ('ambassador'):
        description = I18n.get('descriptionAmbassador');
        break;
      case ('pioneer'):
        description = I18n.get('descriptionPioneer');
        break;
      case ('advocate'):
        description = I18n.get('descriptionAdvocate');
        break;
      default:
        I18n.get('descriptionDefault');
    }
    
    let paymentData = {
      body: {
        paymentInfo: {
          source: token.id,
          amount: Math.round(Number(this.state.amount) * 100),
          currency: "usd",
          receipt_email: trimEnd(userData.email.value),
          description: description,
        },
        userData: {
          firstName: trimEnd(userData.firstName.value),          
          country: trimEnd(userData.country.value.value),
          team: this.state.plan,
        },
        email: trimEnd(userData.email.value)
      }
    }
    if (this.state.ccard.postalCode.value)
      paymentData.body.userData.zipcode = this.state.ccard.postalCode.value;

    this.processPayment(paymentData);
    }
  }

  processPayment = (paymentData, complete = null) => {
    API.post('paymentGateway', '/api', paymentData)
      .then(res => {
        let updatedstate = {
          loading: false
        }

        if (complete)
          complete('success');
        if (res.status === 'error') {

          this.setState({ ...updatedstate }, () => {
            this.paymentStatusPage(`/error`, { errorMessage: res.paymentData.message });
          });
        }
        else {
          updatedstate = {
            ...updatedstate,
            completed: true,
            receipturl: res.paymentData.receipt_url,
            accessCode: res.inviteCode,
            appstore: res.appstore,
            playstore: res.playstore
          }
          this.setState({ ...updatedstate }, () => {
            this.paymentStatusPage(`/success`, { receipturl: res.paymentData.receipt_url, playstore: res.playstore, appstore: res.appstore, accessCode: res.inviteCode });
          });
        }
      })
      .catch(error => {
        if (complete)
          complete('fail');
        this.setState({
          loading: false,
          paymenterror: true,
          errorMessage: 'errorMessage'
        }, () => {
          this.paymentStatusPage(`/error`, {});
        });
      })
  }

  resetpayment = (ev) => {
    ev.preventDefault();
    this.setState({
      ...defaultFormState
    });
  }

  paymentStatusPage = (page, params) => {
    this.props.history.push(page, params);
  }

  render() {
    const { ccard } = this.state;
    let containerclass = ['container', 'page-content-wrapper'];
    if (this.state.loading)
      containerclass.push(styles.blurelement);

    return (
      <div>
        <div className={containerclass.join(' ')}>
          <div className="main-panel">
            <div style={{ margin: 'auto', maxWidth: '800px' }}>
              <div className="whenIpadView">
                <Cart
                  amount={this.state.amount}
                  backToHome={this.backToHome}
                  plan={this.state.plan}
                />
              </div>
              <div>
                <div className="container border">
                  <form onSubmit={this.submit} className='w-100' id="form" noValidate>
                    <BasicInfo
                      inputChange={this.inputChange}
                      fields={this.state.userData}
                      focused={this.inputFocused}
                      blurred={this.inputBlurred}
                      submitted={this.state.submitted}
                      paynow={this.state.paynow}
                      // Country field
                      countryOnchange={this.countryOnchange}
                      // Postal code 
                      ccard={ccard}
                      ccardHandleChange={this.ccardHandleChange}
                      ccardHandleFocus={this.ccardHandleFocus}
                      ccardHandleBlur={this.ccardHandleBlur}
                      createOptions={createOptions(this.props.fontSize)}
                    />
                    <br className="showWhenMobile" />
                    <div className="row no-gutters position-relative">
                      <div className="row no-gutters">
                        <Navtab tabs={[{ id: 1, name: 'paymentDetails' }]} />
                      </div>
                      <div className="container">
                        <div className="payment">
                          <div className="checkout">
                            {/* {(this.state.canMakePayment &&
                              <div className="mb-3" style={{ borderBottom: '1px solid #f0f0f0' }}>
                                <div className="custom-control custom-radio mb-3">
                                  <input type="radio"
                                    id="paymentBtn"
                                    name="paymentType"
                                    value="paybybtn"
                                    className="custom-control-input"
                                    onChange={this.paymentTypeChange}
                                    checked={this.state.paymentType === 'paybybtn'}
                                  />
                                  <label className="custom-control-label" htmlFor="paymentBtn">{I18n.get(this.state.paymentOption)} {I18n.get('Pay')}</label>
                                  <img src={this.state.paymentLogo} className="payment-icon" alt={`${this.state.paymentOption} Pay`} />
                                </div>

                                {this.state.paymentType === 'paybybtn' && (
                                  <PayRequestButtonInfo
                                    handleBlur={this.handleBlur}
                                    paymentRequestClickHandler={this.paymentRequestClickHandler}
                                    handleFocus={this.handleFocus}
                                    handleReady={this.handleReady}
                                    paymentRequest={this.state.paymentRequest}
                                  />
                                )}
                              </div>
                            )} */}
                            <div className="custom-control custom-radio mb-3">
                              <input
                                type="radio"
                                id="paymentCard"
                                name="paymentType"
                                value="paybycard"
                                className="custom-control-input"
                                onChange={this.paymentTypeChange}
                                checked={this.state.paymentType === 'paybycard'}
                              />
                              <label className="custom-control-label" htmlFor="paymentCard">{I18n.get('creditDebitCard')}</label>
                              <img src={cardModal} className="payment-icon" alt="Card Payment" />
                            </div>
                            {this.state.paymentType === 'paybycard' && (
                              <CardInfo
                                ccard={ccard}
                                ccardHandleChange={this.ccardHandleChange}
                                ccardHandleFocus={this.ccardHandleFocus}
                                ccardHandleBlur={this.ccardHandleBlur}
                                createOptions={createOptions(this.props.fontSize)}
                                submit={this.submit}
                              />
                            )}
                            <Footer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading && (
          <AppLoader styles={styles} />
        )}
      </div>
    );
  }
}

export default injectStripe(Checkout);
