import React from 'react'

const PageHeader = (props => {
    return (
        <div className="row no-gutters mt-3 mb-3">
            <h1 style={{ marginLeft: '27px', fontWeight: 700, fontSize : '24px' }}>{props.title}</h1>
        </div>
    )
})

export default PageHeader;