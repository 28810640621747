import React, { Component } from 'react'
import Input from './UI/Input/Input';
import Navtab from './common/Navtab';
import firstnameIcon from '../assets/img/firstNameIcon.png';
import { PostalCodeElement } from 'react-stripe-elements';
import classes from './UI/Input/input.module.scss';
import { I18n } from 'aws-amplify';

class BasicInfo extends Component {

  render() {
    const { fields } = this.props;
    return (
      <div className="row no-gutters position-relative">
        <div className="row no-gutters">
          <Navtab tabs={[{ id: 1, name: 'yourInformation' }]} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <Input elementType="input"
                elementName="firstName"
                label="Name"
                field={fields.firstName}
                focused={this.props.focused}
                blurred={this.props.blurred}
                changed={this.props.inputChange}
                classes={'form-control'}
                submitted={this.props.submitted}
                paynow={this.props.paynow}
              />
              <img src={firstnameIcon} className="firstnameIcon" alt="" />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <Input elementType="input"
                elementName="email"
                label="Email"
                field={fields.email}
                focused={this.props.focused}
                blurred={this.props.blurred}
                changed={this.props.inputChange}
                classes={['form-control']}
                submitted={this.props.submitted}
                paynow={this.props.paynow}
              />
            </div>           
          </div>        
          <div className="row">           
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Input
                elementType="country"
                elementName="country"
                label="Country"
                field={fields.country}
                classes={'form-control'}
                submitted={this.props.submitted}
                paynow={this.props.paynow}
                countryOnchange={this.props.countryOnchange}
                focused={this.props.focused}
                blurred={this.props.blurred}
              />
            </div>          
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group-custom position-relative">
                <label htmlFor="cardpostalcode">{ I18n.get('postalCode')} </label>
                <PostalCodeElement className={`form-control ${(!this.props.ccard.postalCode.isValid && this.props.ccard.postalCode.touched && !this.props.ccard.postalCode.focused) && 'cardValidationError'}`}
                  onChange={this.props.ccardHandleChange}
                  onFocus={this.props.ccardHandleFocus}
                  onBlur={this.props.ccardHandleBlur}
                  {...this.props.createOptions}
                  id="postalCode"
                />
                {(!this.props.ccard.postalCode.isValid && this.props.ccard.postalCode.touched && !this.props.ccard.postalCode.focused) && (
                  <p className={"mb-0 " + classes.InvalidMessage}>{I18n.get(this.props.ccard.postalCode.message)}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicInfo;
