import React from 'react'
import logo from '../../assets/img/logo-hmi-white.png';
import { withRouter } from 'react-router-dom';

const navStyle = {
    backgroundColor: '#222952'
}

class Header extends React.Component {

    redirectUrl = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    }

    render() {
        return (
            <header>
                <nav className="navbar" style={navStyle}>
                    <a className="navbar-brand" href="https://go.hminnovations.org/limited-release">
                        <img src={logo} style={{ padding: '15px', paddingLeft: '1px' }} width="200px" alt="" />
                    </a>
                </nav>
            </header>
        )
    }
}

export default withRouter(Header);