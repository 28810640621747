import React from 'react'
import { I18n } from 'aws-amplify';

const Navtab = (props => {
    return (
        <ul className="nav">
            {props.tabs.map((tab) =>
                <li className="navBarLiPadding" key={tab.id} style={{ background: 'transparent', fontWeight: 700 }}>
                    {I18n.get(tab.name)}
                </li>
            )}
        </ul>
    )
})

export default Navtab;