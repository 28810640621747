import React from 'react';
import { I18n } from 'aws-amplify';

const AppLoader = (props) => {
    return (
        <div className={props.styles.appLoaderWrapper}>
            <div className={props.styles.appLoading}>
                <div className={props.styles.hmiLoadingSpinner}></div>
                <p className="mt-3 mb-0">{I18n.get('processingRequest')}</p>
            </div>
        </div>
    );
}
export default AppLoader;