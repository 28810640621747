import React from 'react'
import { I18n } from 'aws-amplify';

const Footer = (() => {
    return (
        <div className="w-100 d-flex mt-3 footer">
            <p className="my-0 footerText">{I18n.get('agreeText')}
                <span> <a href="https://hminnovations.org/hmi/terms-of-use" rel="noopener noreferrer" target="_blank">{I18n.get('termsofUse')}</a> {I18n.get('and')} <a href="https://hminnovations.org/hmi/privacy-policy" target="_blank" rel="noopener noreferrer">{I18n.get('privacyPolicy')}</a>
                </span>
            </p>
        </div>
    )
})

export default Footer;