import React from 'react';
import { I18n } from 'aws-amplify';

class Pioneer extends React.Component {

    populateList = () => {
        const list = [
            'Pioneer-li-1', 'Pioneer-li-2', 'Pioneer-li-3', 'Pioneer-li-4'
        ];
        return list.map((li, i) => {
            return <li key={i}>
                {I18n.get(li)}
            </li>;
        });
    }

    render() {
        const { logo, type, totalDIV } = this.props;
        return (
            <li className="list-group-item d-flex justify-content-between flex-wrap lh-condensed fontWigthForContentPage titleGroupContent">
                {logo(type)}
                <div className="w-100 d-flex mt-3">
                    <p className="my-0 donation-info">  {I18n.get('Pioneer-P1')}<br className="hideWhenMobileDevice" /> {I18n.get('Pioneer-P2')}</p>
                </div>
                <div className="w-100 d-flex mt-3">
                    <p className="my-0 donation-info"> {I18n.get('Includes')}:</p>
                </div>
                <div className="w-100 d-flex  mb-35">
                    <ul className="dotSymbol includesPadding">
                        {this.populateList()}
                    </ul>
                </div>
                <hr className="mr-12" />
                {totalDIV()}
                <div className="w-100 d-flex mb-3">
                    <div className="col-md-7 offset-md-5 col-lg-7 offset-lg-5 col-xl-7 offset-xl-5 col-sm-12">
                        <p className="my-0 donation-info textMutedCustomized text-right"> <i> {I18n.get('Pioneer-below-text-P1')}<br className="hideWhenMobileDevice" /> {I18n.get('Pioneer-below-text-P2')} </i></p>
                    </div>
                </div>
            </li>
        )
    }
}

export default Pioneer;