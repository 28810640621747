import React, { Component } from 'react';
import SVGSuccessIcon from "../components/icons/SuccessIcon";
import HMILogo from '../assets/img/logo-hmi-blue.png';
import { I18n } from 'aws-amplify';

class SuccessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            receipturl: null,
            playstore: null,
            appstore: null,
            accessCode: null
        }
    }
    /**
     * 
     * Getting receipt URL, invitation code and app stores URL in success state param
     * 
     *  */ 
    componentWillMount() {
        let { receipturl, playstore, appstore, accessCode } = this.props.location.state ? this.props.location.state : {};
        if (receipturl)
            this.setState({ receipturl, playstore, appstore, accessCode });
        else 
            this.backToHome();
    }

    backToHome = () => {
        this.props.history.push(`/`);
    }
    /**
     * 
     * Redirect to external URL when clicking HMI log on footer
     * 
     *  */ 
    redirectUrl = () => {
        window.location = "http://www.hminnovations.org";
        // window.open("http://www.hminnovations.org", '_blank');
    }

    render() {
        return (
            <div>
                <div className="success">
                    <div className="icon Lato-font">
                        <SVGSuccessIcon />
                    </div>
                    <h3 className="title MB12px Lato-font" data-tid="elements_examples.success.title"> {I18n.get('thanks-page-P1')} <br className="hideWhenMobileDevice" />{I18n.get('thanks-page-P2')} <br className="hideWhenMobileDevice" />{I18n.get('thanks-page-P3')}</h3>
                    <p className="message successPageFontWeight Lato-font">
                        <span> {I18n.get('thanks-page-sub-P1')}  <br className="hideWhenMobileDevice" /> {I18n.get('thanks-page-sub-P2')} </span><b>{I18n.get('thanks-page-sub-P2-bold')}</b><br className="hideWhenMobileDevice" /><span>  {I18n.get('thanks-page-sub-P3')}</span>
                    </p>
                    {this.state.receipturl && (
                        <p><a href={this.state.receipturl} target="_blank" rel="noopener noreferrer" className="link_underline">{I18n.get('viewReceipt')}</a></p>
                    )}
                    <hr class="mr-12" style={{ maxWidth: '75', marginTop: '52px', marginBottom: '52px' }}></hr>
                    <div>
                        <img className="footerLogo" alt="" src={HMILogo} onClick={() => this.redirectUrl()} />
                    </div>
                    <div style={{ marginBottom: '50px' }}></div>
                    <p className="footer successPageFontWeight">{I18n.get('helpText')} <a href="mailto:support@hminnovations.org" className="link_underline">support@hminnovations.org</a></p>

                    <div style={{ marginBottom: '25px' }}></div>

                </div>
            </div>
        );
    }
}


export default SuccessPage;
