import React from 'react';
import PageHeader from './common/PageHeader';
import PioneerBanner from '../assets/img/Pioneer_img.png';
import AmbassadorBanner from '../assets/img/Ambassador.png';
import AdvocateBanner from '../assets/img/Advocate.png';
import Ambassador from './plans/Ambassador';
import Pioneer from './plans/Pioneer';
import Advocate from './plans/Advocate';
import { I18n } from 'aws-amplify';

const imgSource = {
    'pioneer' : PioneerBanner,
    'ambassador' : AmbassadorBanner,
    'advocate' : AdvocateBanner
}

class Cart extends React.Component {
    totalDIV = () => {
        return <div className="w-100 d-flex mb-3 mt-2 justify-content-between">
            <span className="totalFont">{I18n.get('Total')}</span>
            <strong className="totalPriceFont mr-12">$<span className="ml-1">{this.props.amount}</span> USD</strong>
        </div>
    }

    changeButton = () => {
        return <button className="btn btn-link backButton float-right UnderlineCustom mr-0 dynamic-logo" ><a href="https://go.hminnovations.org/limited-release">{I18n.get('change')}</a></button>
    }

    logo = (type) => {
        return <div className={`w-100 ${type === 'pioneer' && 'mt-m-19'}`}>
            <div className="containerBox">
                <div className="text-box">
                    <h4 className={`w-100 my-0 ${type === 'pioneer' && 'forPioneerText'}`}>
                        {/* <b className="displayCenter">{I18n.get(type.toUpperCase())}</b> */}
                        {this.changeButton()}
                    </h4>
                </div>
                <img className={`img-responsive ${type === 'pioneer' ? 'img-pioneer-banner' : 'img-banner'}`} alt="" src={imgSource[type]} />
            </div>
        </div>
    }

    displayCartDetails = (type) => {
        if (type === 'ambassador') {
            return <Ambassador
                type={type}
                logo={this.logo}
                totalDIV={this.totalDIV}
            />
        } else if (type === 'pioneer') {
            return <Pioneer
                type={type}
                logo={this.logo}
                totalDIV={this.totalDIV}
            />
        } else if (type === 'advocate') {
            return <Advocate
                type={type}
                logo={this.logo}
                totalDIV={this.totalDIV}
            />
        }
    }

    render() {
        return (
            <div className="container border cart">
                <PageHeader title={I18n.get('oneTimeDonation')} />
                <ul className="list-group mb-3" style={{ paddingTop: '10px' }}>
                    {this.displayCartDetails(this.props.plan)}
                </ul>
            </div>
        )
    }
}

export default Cart;