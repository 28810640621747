import { I18n } from 'aws-amplify';
import en from './en';
import fr from './fr';

const dict = {
    fr,
    en
};

I18n.putVocabularies(dict);
I18n.setLanguage('en');