import React, { Component } from 'react';
import SVGErrorIcon from "../components/icons/ErrorIcon";
import { I18n } from 'aws-amplify';

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            receipturl: null,
            errorMessage: null
        }
    }

    componentWillMount() {
        let { errorMessage } = this.props.location.state ? this.props.location.state : {};
        if (errorMessage)
            this.setState({ errorMessage });
        else 
            this.backToHome();
    }

    backToHome = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="success error-success">
                <SVGErrorIcon />
                <h3 className="title" data-tid="elements_examples.success.title">{I18n.get('errorPageMessage')}</h3>
                <p className="message"><span data-tid="elements_examples.success.message">{this.state.errorMessage}</span></p>
                {this.state.receipturl && (
                    <p><a href={this.state.receipturl}>{I18n.get('downloadReceipt')}</a></p>
                )}
                    <button className="btn btn-link" onClick={this.backToHome}> {I18n.get('backtoHome')}</button>
            </div>
        );
    }
}


export default ErrorPage;
