import React from 'react';
import Select, { components } from 'react-select';
import classes from './input.module.scss';
import { I18n } from 'aws-amplify';

const Input = (props) => {
    return (
        <components.Input {...props} name='country' />
    );
};

const input = (props) => {
    let inputElement = null;
    const inputClasses = [props.classes];
    let { field } = props;
    const isInValid = (!field.valid && ((field.touched && !field.focused)));
    if (isInValid) {
        inputClasses.push(classes.Invalid);
    }

    const countryError = {
        backgroundColor: '#fff7f6',
        border: '1px solid #ff748d'
    }

    const countryStyle = { "control": styles => ({ ...styles, ...(isInValid && countryError) }) }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input
                className={inputClasses.join(' ')}
                value={field.value}
                name={props.elementName}
                id={props.elementName}
                onChange={props.changed}
                onFocus={props.focused}
                onBlur={props.blurred}
                maxLength="50"
            />;
            break;
        case ('country'):
            inputElement = <Select
                id={props.elementName}
                name={props.elementName}
                value={field.options[0]}
                onChange={props.countryOnchange}
                options={field.options}
                className="react-select custom-react-select-width"
                //isSearchable={true}
                components={{ Input }}
                classNamePrefix="custom-select"
                styles={countryStyle}
                onFocus={props.focused}
                onBlur={event => event.preventDefault()}
                defaultValue={field.options[0]}
            />
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }
    return (
        <div className="form-group position-relative">
            <label htmlFor={props.elementName}>{I18n.get(props.label)}</label>
            {inputElement}
            {((!field.valid && ((field.touched && !field.focused)))) && (
                <p className={classes.InvalidMessage}>{I18n.get(field.message)}</p>
            )}
        </div>     
    );
};

export default input;

