// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "paymentGateway",
            "endpoint": "https://8a7zdvgjmb.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
