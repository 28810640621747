export const cardBrandToPfClass = {
  'visa': 'img-visa',
  'mastercard': 'img-mastercard',
  'amex': 'img-amex',
  'discover': 'img-discover',
  'diners': 'img-diners',
  'jcb': 'img-jcb',
  'unknown': 'pf-credit-card',
};

export const defaultFormState = {
  card: '',
  userData: {
    firstName: {
      value: '',
      valid: false,
      touched: false,
      message: 'nameNotEmpty',
      focused: false,
      validation: {
        isRequired: true,
        isCharacters: true,
        minLength: 2
      },
      errorMessages: {
        isCharacters: 'nameNotContainSpecialChars',
        isRequired: 'nameNotEmpty',
        minLength: 'validName'
      }
    },
    email: {
      value: '',
      valid: false,
      touched: false,
      message: 'emailNotEmpty',
      focused: false,
      validation: {
        isRequired: true,
        isEmail: true,
      },
      errorMessages: {
        isRequired: 'emailNotEmpty',
        isEmail: 'validEmail',
      }
    },
    country: {
      value: { value: "US"},
      valid: true,
      touched: false,
      message: 'selectCountry',
      focused: false,
      options: [],
      validation: {
        isRequired: true,
      },
      errorMessages: {
        isRequired: 'selectCountry',
      }
    }
  },
  ccard: {
    cardNumber: {
      isValid: false,
      message: 'cardNumberNotEmpty',
      touched: false,
      focused: false,
      errorMessages: {
        empty: 'cardNumberNotEmpty',
        complete: 'validCardNumber',
      }
    },
    cardExpiry: {
      isValid: false,
      message: 'expiryDateNotEmpty',
      touched: false,
      focused: false,
      errorMessages: {
        empty: 'expiryDateNotEmpty',
        complete: 'validExpiryDate',
      }
    },
    cardCvc: {
      isValid: false,
      message: 'CVCNotEmpty',
      touched: false,
      focused: false,
      errorMessages: {
        empty: 'CVCNotEmpty',
        complete: 'ValidCVC',
      }
    },
    postalCode: {
      isValid: false,
      message: 'postalCodeNotEmpty',
      touched: false,
      focused: false,
      errorMessages: {
        empty: 'postalCodeNotEmpty',
        complete: 'validPostalCode',
      }
    }
  },
  formIsValid: false,
  ccardIsValid: false,
  loading: false,
  completed: false,
  paymenterror: false,
  receipturl: '',
  submitted: false,
  paynow: false,
  paymentType: null,
  paymentoption: null,
  accessCode: null,
  appstore: null,
  playstore: null,
  plan: null
}

export const plans = ['ambassador', 'pioneer', 'advocate'];

export const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: '14px',
        color: '#424770',//'#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#d5d5dc',
          fontSize: '14px'
        },
      }
    }
  };
};
